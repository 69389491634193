import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplications,
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilUser,
  cilLockLocked,
  cilLockUnlocked,
  cilBike,
  cilSettings,
  cilUserFollow,
  cilSave,
  cilBan,
  cilLocationPin,
  cilPlus,
  cilX,
  cilMap,
  cilActionRedo,
  cilActionUndo,
  cilPencil,
  cilListRich,
  cilList,
  cilPeople,
  cilOptions,
  cilAccountLogout,
  cilSearch,
  cilBasket,
  cilCalendarCheck,
  cilCheck,
  cilBell,
  cilBellExclamation,
  cilDataTransferDown,
  cilBullhorn,
  cilCreditCard,
  cilTask,
  cilCloudDownload,
  cilCloudUpload,
  cilReload,
  cilDiamond,
  cilChatBubble,
  cilExternalLink,
  cilAvTimer,
  cilChevronTop,
  cilChevronBottom,
  cilBuilding,
  cilShortText,
  cilWarning,
  cilCopy,
  cilPrint,
  cilLibraryAdd,
  cilLoop,
  cilColorBorder,
  cilCarAlt,
  cilClock,
  cilSync,
  cilChartLine,
  cilVerticalAlignBottom,
  cilArrowCircleRight,
  cilArrowCircleLeft,
  cilArrowThickToRight,
  cilArrowThickToLeft,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplications,
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilUser,
  cilLockLocked,
  cilLockUnlocked,
  cilBike,
  cilSettings,
  cilUserFollow,
  cilSave,
  cilBan,
  cilLocationPin,
  cilPlus,
  cilX,
  cilMap,
  cilActionRedo,
  cilActionUndo,
  cilPencil,
  cilListRich,
  cilList,
  cilPeople,
  cilOptions,
  cilAccountLogout,
  cilSearch,
  cilBasket,
  cilCalendarCheck,
  cilCheck,
  cilBell,
  cilBellExclamation,
  cilDataTransferDown,
  cilBullhorn,
  cilCreditCard,
  cilTask,
  cilCloudDownload,
  cilCloudUpload,
  cilReload,
  cilDiamond,
  cilChatBubble,
  cilExternalLink,
  cilAvTimer,
  cilChevronTop,
  cilChevronBottom,
  cilBuilding,
  cilShortText,
  cilWarning,
  cilCopy,
  cilPrint,
  cilLibraryAdd,
  cilLoop,
  cilColorBorder,
  cilCarAlt,
  cilClock,
  cilSync,
  cilChartLine,
  cilVerticalAlignBottom,
  cilArrowCircleRight,
  cilArrowCircleLeft,
  cilArrowThickToRight,
  cilArrowThickToLeft,
})
